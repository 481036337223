import React from 'react'
import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LanguageIcon from '@material-ui/icons/Language'
import CompanySymbol from 'url:../img/company.png'
import EmployeeSymbol from 'url:../img/employees.png'
import LocationIcon from 'url:../img/location.png'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import EmailIcon from '@material-ui/icons/Email'
import EventIcon from '@material-ui/icons/Event'

const useStyles = makeStyles(theme => ({
  cardHeader: {
    height: 188,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid #d9d9d9'
  },
  profileCard: {
    borderRadius: 10,
    margin: 40
  },
  aboutCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40
  },
  companyCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 30,
    position: 'relative'
  },
  profileCardBody: {
    height: 200
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5'
  },
  container: {
    maxWidth: 800,
    display: 'block',
    marginLeft: 'auto',
    width: '100%',
    backgroundColor: '#F5F5F5'
  },
  sidebar: {
    width: 256,
    height: '100%',
    marginRight: 'auto',
    backgroundColor: '#F5F5F5',
    marginTop: '40px'
  },
  profilePicture: {
    height: 160,
    width: 160,
    position: 'absolute',
    top: 88,
    borderRadius: 150,
    border: '2px solid white',
    left: 28
  },
  subtitle: {
    fontSize: '14px',
    color: '#6B7280',
    marginTop: '5px'
  },
  agentName: {
    fontSize: '24px',
    fontWeight: 700
  },
  button: {
    marginRight: 5
  },
  smallScreenButton: {
    width: '100%',
    marginBottom: 10
  },
  companyPicture: {
    height: 32,
    width: 32,
    display: 'block',
    marginLeft: 'auto',
    objectFit: 'contain',
    marginRight: 'auto'
  },
  companyGrid: {
    display: 'grid',
    gridTemplateColumns: '150px auto',
    gridRowGap: '30px'
  },
  companyImageContainer: {
    width: 60,
    marginRight: 20,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
    overflow: 'hidden'
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -15
  },
  link: {
    color: '#0CA6F9',
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'none'
  },
  attr: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#6B7280'
  }
}))

export default function ExpandedInfo({ profileInfo }) {
  const classes = useStyles()
  return (
    <div>
      <div>
        <Card raised style={{ padding: 20, marginBottom: 20 }}>
          <div className={classes.agentName}>
            Contact Info
          </div>
          <div>
            {profileInfo.attributes.social_media_links?.linkedin && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <LinkedInIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div>
                  <a className={classes.link} href={profileInfo.attributes.social_media_links?.linkedin} target='_blank' rel='noopener noreferrer'>LinkedIn</a>
                </div>
              </div>
            )}
            {profileInfo.attributes.social_media_links?.twitter && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <TwitterIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div>
                  <a className={classes.link} href={profileInfo.attributes.social_media_links?.twitter} target='_blank' rel='noopener noreferrer'>Twitter</a>
                </div>
              </div>
            )}
            {profileInfo?.attributes?.phone && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <PhoneInTalkIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div className={classes.link}>
                  {profileInfo?.attributes?.phone}
                </div>
              </div>
            )}
            {profileInfo?.attributes?.email && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <EmailIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div>
                  <a className={classes.link} href={`mailto:${profileInfo.attributes.email}`}>{profileInfo.attributes.email}</a>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
      <div>
        <Card raised style={{ padding: 20 }}>
          <div className={classes.agentName}>
            Company Info
          </div>
          <div>
            {profileInfo.attributes.company_attributes?.website && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <LanguageIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div>
                  <a className={classes.link} href={`https://${profileInfo.attributes.company_attributes?.website}`} target='_blank' rel='noopener noreferrer'>{profileInfo.attributes.company_attributes?.website}</a>
                </div>
              </div>
            )}
            {profileInfo.attributes.company_attributes?.industry && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <img src={CompanySymbol} style={{ marginRight: 10 }} />
                <div className={classes.attr}>
                  {profileInfo.attributes.company_attributes?.industry}
                </div>
              </div>
            )}
            {profileInfo.attributes.company_attributes?.company_size && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <img src={EmployeeSymbol} style={{ marginRight: 10 }} />
                <div className={classes.attr}>
                  {profileInfo.attributes.company_attributes?.company_size}
                </div>
              </div>
            )}
            {profileInfo.attributes.company_attributes?.founded && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <EventIcon htmlColor={profileInfo?.attributes?.cta_color || '#0CA6F9'} style={{ marginRight: 10 }} />
                <div className={classes.attr}>
                  {profileInfo.attributes.company_attributes?.founded}
                </div>
              </div>
            )}
            {profileInfo.attributes.company_attributes?.headquarters && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                <img src={LocationIcon} style={{ marginRight: 10 }} />
                <div className={classes.attr}>
                  {profileInfo.attributes.company_attributes?.headquarters}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}