import React, { useState, forwardRef, useEffect } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { IconButton, Dialog, Button, TextField, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import { createCalendarEvent } from '../api/calendars'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  dayWithDotContainer: {
    position: 'relative'
  },
  dayWithDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%'
  },
  slotButton: {
    border: '1px solid rgb(0,0,0)',
    height: 40,
    fontSize: '13px',
    textTransform: 'none',
    width: 90,
    padding: 10
  },
  selectedSlotButton: {
    border: '1px solid rgb(0,0,0)',
    height: 40,
    fontSize: '13px',
    textTransform: 'none',
    backgroundColor: '#3f51b5',
    color: 'white',
    width: 90,
    padding: 10
  },
  textBox: {
    marginBottom: 20,
    maxWidth: 400
  }
}))

function SlotButton (props) {
  const classes = useStyles()
  const selected = props.selected
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      fullWidth
      className={selected ? classes.selectedSlotButton : classes.slotButton}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  )
}

const cfUsers = [
  'billy-bateman',
  'jeremiah-johnson',
  'taylor-fletcher-1',
  'jenny-young',
  'evan-mckague',
  'david-elkington',
  'pete-ketchum',
  'steve-eror'
]
const appURLs = {
  dev: 'local.chatfunnels.dev:12345',
  beta: 'beta.getsignals.ai',
  prod: 'app.getsignals.ai'
}

const url = new URL(window.location.href)

let env = 'prod'
let referenceCode = url.pathname.replace(/^\//, '')
if (url.hostname === 'local.chatfunnels.dev') {
  env = 'dev'
} else if (url.pathname.startsWith('/0/') || cfUsers.includes(referenceCode)) {
  // The hard-coded list of cfUsers is for backwards compatilibity. Moving forward, the
  // generated beta URLs will include a /0/ prefix, but just in case URLs without that
  // prefix have been distributed, this will allow the old urls to work
  env = 'beta'
  if (url.pathname.startsWith('/0/')) {
    referenceCode = url.pathname.substr(3)
  }
}

const appURL = appURLs[env]

const defaultMeeting = {
  attributes: {
    name: 'Meeting',
    length_in_minutes: 60,
    reminder_lead_time: 15,
    lead_time: 30,
    description: "Let's chat!"
  }
}

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='left' ref={ref} {...props} timeout={500} />
})

const CalendarDialog = props => {
  const classes = useStyles()
  const [finalDate, setFinalDate] = useState(null)
  const [formattedDate, setFormattedDate] = useState()
  const [attendeeName, setAttendeeName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [notes, setNotes] = useState('')
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [calendarErrors, setCalendarErrors] = useState([])
  const profileInfo = props.profileInfo
  const meetingInfo = props.meetingInfo || defaultMeeting
  const timezone = props.timezone
  const small = props.small
  const medium = props.medium

  // From parent state:
  const slots = props.slots
  const date = props.date
  const changeDate = props.changeDate
  const availableDates = props.availableDates

  const sendCalendarInvite = () => {
    const end = moment(finalDate).add(meetingInfo.attributes?.length_in_minutes, 'minutes')
    if (!loading && profileInfo.attributes && finalDate) {
      setLoading(true)
      createCalendarEvent({
        start: finalDate,
        end,
        description: meetingInfo.attributes.description,
        userId: profileInfo.attributes.user_id,
        tenantId: profileInfo.attributes.tenant_id,
        email,
        attendeeName,
        phone,
        appURL,
        reminderLeadTime: meetingInfo.attributes?.reminder_lead_time,
        notes,
        meetingName: meetingInfo.attributes?.name,
        signatureText: profileInfo.attributes?.signature_text,
        meetingTypeId: meetingInfo?.id
      })
        .then(response => {
          if (response.ok) {
            props.handleSuccess(response)
            setLoading(false)
          } else {
            setCalendarErrors(response.errors)
            setLoading(false)
          }
        })
    }
  }

  const renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    if (availableDates.includes(date.format('YYYY/MM/DD')) && dayInCurrentMonth) {
      return (
        <div className={classes.dayWithDotContainer}>
          {dayComponent}
          <div className={classes.dayWithDot} />
        </div>
      )
    }

    return dayComponent
  }

  const disableNotAvailable = date => {
    return !availableDates.includes(date.format('YYYY/MM/DD'))
  }

  // const primaryColor = props.profileInfo.attributes.primary_color || '#975ce6'
  // const contrastColor = props.profileInfo.attributes.contrast_color || '#FFFFFF'

  useEffect(() => {
    if (date) {
      const x = moment(date)
      const y = x.format('YYYY/MM/DD')
      setFormattedDate(y)
      setFinalDate(null)
    }
  }, [date])

  useEffect(() => {
    if (finalDate && attendeeName && email) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [finalDate, attendeeName, email])

  const hasProfilePicture = Boolean(profileInfo.attributes.profile_picture_url)
  const hasCompanyLogo = Boolean(profileInfo.attributes.company_attributes?.company_logo_url)
  let imageUrl = null

  if (hasProfilePicture) {
    imageUrl = profileInfo.attributes.profile_picture_url
  } else if (!hasProfilePicture && hasCompanyLogo) {
    imageUrl = profileInfo.attributes.company_attributes?.company_logo_url
  }

  const hasImage = Boolean(imageUrl)

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <div style={{ display: 'flex', flexWrap: 'wrap', height: '100%', justifyContent: 'center' }}>
          <div style={{ flexBasis: '200px', flexGrow: 1, backgroundColor: profileInfo.attributes?.primary_color || 'green', paddingTop: 30, paddingBottom: 30 }}>
            <IconButton style={{ padding: 25, color: profileInfo.attributes?.contrast_color || 'white', position: 'absolute', marginLeft: 25 }} onClick={props.onBack}>
              <ArrowBackIcon style={{ transform: 'scale(1.5)' }} />
            </IconButton>
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'block', marginTop: 'auto', marginBottom: 'auto' }}>
                {hasImage && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imageUrl} style={{ borderRadius: 100, height: 125, width: 125, border: `3px solid ${profileInfo.attributes?.contrast_color}` }} />
                  </div>
                )}
                <div style={{ fontSize: 28, textAlign: 'center', color: profileInfo.attributes?.contrast_color, marginTop: 15 }}>
                  {profileInfo.attributes?.agent_name}
                </div>
                <div style={{ fontSize: 14, textAlign: 'center', color: profileInfo.attributes?.contrast_color }}>
                  {profileInfo.attributes.company_attributes?.company_name || ''}
                </div>
                <div style={{ fontSize: 14, textAlign: 'center', color: profileInfo.attributes?.contrast_color }}>
                  {profileInfo.attributes.company_attributes?.headquarters || ''}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                  <Button
                    variant='outlined'
                    onClick={props.onClose}
                    color='secondary'
                    style={{ backgroundColor: profileInfo.attributes.primary_color, color: profileInfo.attributes.contrast_color, border: `1px solid ${profileInfo.attributes.contrast_color}` }}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 3 }}>
            <div style={{ fontSize: 32, textAlign: 'center', marginTop: 60, marginBottom: 20 }}>
              Select a Date
            </div>
            <div style={{ padding: small ? '0px' : '75px 82px 0px 82px' }}>
              <div style={{ transform: small ? 'scale(1)' : medium ? 'scale(1.3)' : 'scale(1.5)', display: 'flex', justifyContent: 'center' }}>
                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                  <DatePicker
                    renderDay={renderDayInPicker}
                    shouldDisableDate={disableNotAvailable}
                    autoOk
                    disableToolbar
                    color='red'
                    orientation='landscape'
                    variant='static'
                    openTo='date'
                    value={date}
                    onChange={changeDate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div>
              <div style={{ fontSize: 32, textAlign: 'center', marginTop: medium ? 40 : 75, marginBottom: 20 }}>
                Select a Time
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '100%', maxWidth: small ? 350 : medium ? 440 : 490, border: '1px solid', borderRadius: 7, paddingTop: 10, paddingBottom: 10 }}>
                  {formattedDate && slots && Object.keys(slots)?.length && slots[formattedDate]?.length &&
                    <div style={{
                      display: 'flex',
                      // overflowX: 'scroll',
                      marginLeft: 20,
                      flexWrap: 'wrap',
                      maxHeight: small ? null : 130,
                      overflowY: 'scroll'
                    }}
                    >
                      {
                        slots[formattedDate].map((slot, index) => {
                          const text = moment(slot).format('hh:mm A')
                          return (
                            <div style={{ padding: '10px 10px 10px 0' }} key={index}>
                              <SlotButton
                                text={text}
                                selected={finalDate === slot}
                                onClick={() => setFinalDate(slot)}
                              />
                            </div>
                          )
                        })
                      }
                    </div>}
                </div>
              </div>
            </div>
            <div style={{ fontSize: 15, textAlign: 'center', marginTop: 20, marginBottom: 20, color: '#565656' }}>
              <i>* All times are based on your current time zone ({timezone.replace('_', ' ')})*</i>
            </div>
          </div>
          <div style={{ height: 'calc(100% - 120px)', backgroundColor: '#c3c3c3', width: 1, marginTop: 60 }} />
          <div style={{ flexBasis: '200px', flexGrow: 5 }}>
            <div style={{ fontSize: 32, textAlign: 'center', marginTop: 60 }}>
              <div>
                Book Meeting
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30, fontSize: 36 }}>
                <div style={{ borderRadius: 50, backgroundColor: profileInfo.attributes?.primary_color, height: 10, width: 10, marginRight: 10 }} />
                {meetingInfo.attributes?.name}
              </div>
              <div style={{ fontSize: 16 }}>
                {meetingInfo.attributes?.length_in_minutes} minutes
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>

              <div style={{ margin: '35px', maxWidth: 400 }}>
                <TextField
                  variant='outlined'
                  required
                  className={classes.textBox}
                  label='Name (Required)'
                  onChange={(e) => setAttendeeName(e.target.value)}
                  fullWidth
                />
                <TextField
                  variant='outlined'
                  className={classes.textBox}
                  required
                  id='email'
                  name='email'
                  label='Email (Required)'
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
                <TextField
                  variant='outlined'
                  className={classes.textBox}
                  label='Phone '
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                />
                <TextField
                  variant='outlined'
                  className={classes.textBox}
                  label='Meeting Notes'
                  onChange={(e) => setNotes(e.target.value)}
                  fullWidth
                  multiline
                  rows={8}
                />
                <Button
                  onClick={() => {
                    sendCalendarInvite()
                    setCalendarErrors([])
                  }}
                  variant='contained'
                  fullWidth
                  disabled={!enabled || loading}
                  style={enabled ? { backgroundColor: profileInfo.attributes.primary_color, color: profileInfo.attributes.contrast_color } : {}}
                >
                  Book Now
                </Button>
                {calendarErrors.map((error, index) => (
                  <div key={index} style={{ fontSize: 12, color: 'red', marginTop: 20 }}>
                    {error.detail}
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, visibility: loading ? 'visible' : 'hidden' }}>
                  <CircularProgress size={40} style={{ color: profileInfo.attributes.primary_color }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default CalendarDialog
